import {
  ApolloClient,
  ApolloProvider,
  FieldPolicy,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Reference,
  from,
  split,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link"
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link"
import { createClient as createWsClient } from "graphql-ws"
import { TFunction } from "i18next"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { loadConfig, registerSignOutProc } from "@/contexts/CognitoContext"
import { SnackbackOpenProps } from "@/contexts/SnackbarContext"
import useAuth from "@/hooks/useAuth"
import useSnackbar from "@/hooks/useSnackbar"
import { globalErrorHandler } from "@/utils/globalErrorHandler"

let client: ApolloClient<NormalizedCacheObject>

export const getClient = () => {
  return client
}

type KeyArgs = FieldPolicy<any>["keyArgs"]

// A basic field policy that uses options.args.{skip,take} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.
export function myOffsetLimitPagination<T = Reference>(
  keyArgs: KeyArgs = false,
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged = existing ? existing.slice(0) : []

      if (incoming) {
        if (args) {
          // Assume an offset of 0 if args.offset omitted.
          const { skip = 0 } = args
          for (let i = 0; i < incoming.length; ++i) {
            merged[skip + i] = incoming[i]
          }
        } else {
          // It's unusual (probably a mistake) for a paginated field not
          // to receive any arguments, so you might prefer to throw an
          // exception here, instead of recovering by appending incoming
          // onto the existing array.
          merged.push(...incoming)
        }
      }

      return merged
    },
  }
}

const createPaginatedCachePolicy = () => {
  return {
    ...myOffsetLimitPagination(["where", "orderBy"]),
    read(existing: any[], { args }: any) {
      const skip = args?.skip as number | undefined
      const take = args?.take as number | undefined

      if (skip !== undefined && take !== undefined) {
        return existing && existing.slice(skip, skip + take)
      }
    },
  }
}

const createClient = (
  getToken: () => Promise<string>,
  openSnackbar: Dispatch<SetStateAction<SnackbackOpenProps>>,
  t: TFunction<"form" | "auth" | "common" | "enums" | "message", undefined>,
): ApolloClient<NormalizedCacheObject> => {
  const config = loadConfig()
  if (!config)
    throw new Error("No config found when initializing graphql client")

  const httpLink = new HttpLink({
    uri: config.graphqlApiUri,
  })

  const wsLink =
    process.env.NODE_ENV === "development"
      ? new GraphQLWsLink(
          createWsClient({
            url: config.graphqlApiUri
              .replace("http", "ws")
              .replace(":3000", ":4000"),
          }),
        )
      : createSubscriptionHandshakeLink(
          {
            auth: {
              type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
              jwtToken: getToken,
            },
            region: config.awsRegion,
            url: config.graphqlApiUri.replace("https://", "wss://"),
          },
          httpLink,
        )

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            listPriorities: createPaginatedCachePolicy(),
            listDomains: createPaginatedCachePolicy(),
            listProjects: createPaginatedCachePolicy(),
            listWorkTypes: createPaginatedCachePolicy(),
            listLanguages: createPaginatedCachePolicy(),
            listWorkflowTemplates: createPaginatedCachePolicy(),
            listServices: createPaginatedCachePolicy(),
            listTeams: createPaginatedCachePolicy(),
            listTimeZones: createPaginatedCachePolicy(),
            listPresenceTypes: createPaginatedCachePolicy(),
            listBillingTypes: createPaginatedCachePolicy(),
            listTaxes: createPaginatedCachePolicy(),
            listTaxSchemas: createPaginatedCachePolicy(),
            listPaymentTypes: createPaginatedCachePolicy(),
            listOrganizations: createPaginatedCachePolicy(),
            listInvoiceTypes: createPaginatedCachePolicy(),
          },
        },
      },
    }),
    link: from([
      onError((error) => {
        return globalErrorHandler(error, openSnackbar, t)
      }),
      createAuthLink({
        url: config.graphqlApiUri,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: getToken,
        },
        region: config.awsRegion,
      }),
      split(
        (op) => {
          const { operation } = op.query.definitions[0] as any
          return operation !== "subscription"
        },
        httpLink,
        wsLink,
      ),
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      query: {
        fetchPolicy: "no-cache",
      },
    },
  })
}

const signOut = async () => {
  await client.clearStore()
}

export const GraphqlProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { getToken } = useAuth()
  const { openSnackbar } = useSnackbar()
  const { t } = useTranslation()
  if (!client) {
    client = createClient(getToken, openSnackbar, t)
    registerSignOutProc("graphql", signOut)
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

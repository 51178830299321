import InputBase from "@mui/material/InputBase"
import { alpha } from "@mui/material/styles"
import { useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import IconSearch from "@/components/icons/IconSearch"
import { useGlobalSearchLazyQuery } from "@/graphql"

const Search = styled("div")<{ $error: boolean }>(({ $error }) => ({
  position: "relative",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "#F0F4F8",
  borderRadius: "20px",
  backgroundColor: alpha("#F0F0F0", 0.25),
  marginLeft: 0,
  width: "100%",
  animation: $error ? "shake" : "",
  animationDuration: "0.4s",
  animationTimingFunction: "ease-out",
  "@keyframes shake": {
    "0%": {
      borderColor: alpha("#FF0000", 0.2),
      marginRight: "0rem",
      marginLeft: "0rem",
    },
    "25%": {
      marginRight: "0.5rem",
      marginLeft: "-0.5rem",
    },
    "50%": {
      borderColor: alpha("#FF0000", 0.5),
      marginRight: "-0.5rem",
      marginLeft: "0.5rem",
    },
    "100%": {
      borderColor: "#F0F4F8",
      marginRight: "0rem",
      marginLeft: "0rem",
    },
  },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    textOverflow: "ellipsis",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:hover, &:focus": {
        width: "20ch",
      },
    },
  },
}))

const GlobalSearchBar: React.FC = () => {
  const { t } = useTranslation()
  const [searchQuery] = useGlobalSearchLazyQuery()
  const navigate = useNavigate()
  const [query, setQuery] = useState<string>("")
  const inputRef = useRef<HTMLInputElement>()
  const [error, setError] = useState(false)

  const handleInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (error) {
        setError(false)
      }

      setQuery(event.target.value)
    },
    [error, setQuery, setError],
  )

  const handleSearch = useCallback(
    async (event: any) => {
      event.preventDefault()
      const query = event.target.elements[0].value as string

      if (query.length > 0) {
        const result = await searchQuery({
          variables: {
            query: { query },
          },
        })

        if (result.data?.globalSearch) {
          const path = `${result.data?.globalSearch.type}/${result.data?.globalSearch.id}`
          navigate(path)
          setQuery("")
          inputRef.current?.blur()
        } else {
          setError(true)
        }
      }
    },
    [searchQuery, navigate, setQuery, setError],
  )

  return (
    <Search $error={error}>
      <SearchIconWrapper>
        <IconSearch />
      </SearchIconWrapper>
      <form onSubmit={handleSearch}>
        <StyledInputBase
          inputRef={inputRef}
          placeholder={t("GlobalSearchBar.Placeholder")}
          value={query}
          onChange={handleInput}
          spellCheck={false}
        />
      </form>
    </Search>
  )
}

export default GlobalSearchBar

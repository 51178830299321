import CircularProgress from "@mui/material/CircularProgress"
import LinearProgress from "@mui/material/LinearProgress"
import styled from "styled-components"

const MuiCircularProgress = styled(CircularProgress)(
  ({ theme: { palette } }) => ({
    "&&": {
      "&.MuiCircularProgress": {
        "&-colorPrimary": {
          color: palette.primary.main,
        },
        "&-colorSecondary": {
          color: palette.primary.secondary,
        },
        "&-colorSuccess": {
          color: palette.progress.success,
        },
      },
    },
  }),
)

const MuiLinearProgress = styled(LinearProgress)(({ theme: { palette } }) => ({
  "&&": {
    height: "10px",
    borderRadius: "16px",
  },
  "&.MuiLinearProgress": {
    "&-colorPrimary": {
      background: palette.progress.background,
      ".MuiLinearProgress-barColorPrimary": {
        backgroundColor: palette.primary.main,
      },
    },
    "&-colorSecondary": {
      background: palette.progress.background,
      ".MuiLinearProgress-barColorSecondary": {
        backgroundColor: palette.primary.secondary,
      },
    },
    "&-colorSuccess": {
      background: palette.progress.background,
      ".MuiLinearProgress-barColorSuccess": {
        backgroundColor: palette.progress.success,
      },
    },
  },
}))

const MuiCircularProgressBox = styled("div")<{
  $transparentBackground: boolean
}>(({ theme, $transparentBackground }) => ({
  "&&": {
    position: "relative",
    display: "inline-flex",
    backgroundColor: $transparentBackground
      ? "none"
      : theme.palette.progress.background,
    borderRadius: "50%",
  },
}))

const MuiCircularProgressTypography = styled.div`
  && {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
  }
`
export {
  MuiCircularProgress,
  MuiCircularProgressBox,
  MuiCircularProgressTypography,
  MuiLinearProgress,
}

import { Menu } from "@mui/material"
import React, { useCallback, useEffect, useId, useMemo, useState } from "react"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"

import AvatarField from "@/components/AvatarField"
import IconButton from "@/components/IconButton"
import MenuItem from "@/components/MenuItem"
import { useGetUserProfileQuery } from "@/graphql"
import useAuth from "@/hooks/useAuth"
import useRefreshAvatar from "@/hooks/useRefreshAvatar"

const NavbarUserDropdown: React.FC = () => {
  const [anchorMenu, setAnchorMenu] = useState<any>(null)
  const { avatarKey } = useRefreshAvatar()
  const navigate = useNavigate()
  const { signOut } = useAuth()
  const menuId = useId()

  const { data: userData, refetch } = useGetUserProfileQuery()
  const [avatarFileName, setAvatarFileName] = useState<string>(
    userData?.getUserProfile?.avatarFileName ?? "",
  )

  const fullName = useMemo(() => {
    return `${userData?.getUserProfile?.firstName} ${userData?.getUserProfile?.lastName}`
  }, [userData?.getUserProfile])

  useEffect(() => {
    refetch().then((result) => {
      const updatedData = result.data
      setAvatarFileName(updatedData?.getUserProfile?.avatarFileName ?? "")
    })
  }, [avatarKey, refetch, setAvatarFileName])

  const toggleMenu = useCallback(
    (event: React.SyntheticEvent) => {
      setAnchorMenu(event.currentTarget)
    },
    [setAnchorMenu],
  )

  const closeMenu = useCallback(() => {
    setAnchorMenu(null)
  }, [setAnchorMenu])

  const handleSignOut = useCallback(async () => {
    await signOut()
    localStorage.removeItem("isLogin")
    navigate("/auth/signIn")
  }, [signOut, navigate])

  const handleProfile = useCallback(() => {
    closeMenu()
    navigate("/profile")
  }, [closeMenu, navigate])

  const handleChangePassword = useCallback(() => {
    closeMenu()
    navigate("/changePassword")
  }, [closeMenu, navigate])

  return (
    <>
      <IconButton onClick={toggleMenu} title={fullName}>
        <AvatarField
          key={avatarFileName}
          value={avatarFileName}
          disabled={true}
        />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleProfile}>
          <Trans>Profile.Title</Trans>
        </MenuItem>
        {userData?.getUserProfile.status !== "EXTERNAL_PROVIDER" ? (
          <MenuItem onClick={handleChangePassword}>
            <Trans>Profile.ChangePassword</Trans>
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleSignOut}>
          <Trans>Logout</Trans>
        </MenuItem>
      </Menu>
    </>
  )
}

export default NavbarUserDropdown

// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LP: "LP",
}

export const UNICODE = {
  rightArrow: "\u2192",
}

export const styleMt6 = { marginTop: "6px" }
export const styleDisplayNone = { display: "none" }
export const styleFontWeight400 = { fontWeight: 400 }
export const styleFontWeight700 = { fontWeight: 700 }
export const styleMt5Mb5 = { marginTop: "5px", marginBottom: "5px" }
export const styleMinHeight38 = { minHeight: 38 }

export const styleFlexCenter = { display: "flex", alignItems: "center" }
export const sxTextAlignRight = { textAlign: "right" }

export const sxAlignCenter = { alignItems: "center" }
export const sxColorAlmostBlack = { color: "rgba(0,0,0,0.8)" }
export const sxColorWhite = { color: "white" }
export const sxFlexCenter20 = {
  display: "flex",
  alignItems: "center",
  height: 20,
}
export const sxFlexGrow1 = { flexGrow: 1 }
export const sxMl1 = { ml: 1 }
export const sxMl4 = { marginLeft: 4 }
export const sxMl8 = { marginLeft: "8px" }
export const sxMr2 = { mr: 2 }
export const sxMt0 = { mt: 0 }
export const sxMt1 = { mt: 1 }
export const sxP6 = { p: 6 }
export const sxPb3 = { pb: 3 }
export const sxPb8 = { pb: 8 }
export const sxWidth100Pc = { width: "100%" }
export const sxXs12 = { xs: 12 }

export const sizeMd2 = { md: 2 }
export const sizeMd9 = { md: 9 }
export const sizeMd10Xs12 = { md: 10, xs: 12 }
export const sizeMd12 = { md: 12 }

export const sizeXs12MdGrow = { xs: 12, md: "grow" }

export const styleMin20 = {
  minWidth: 20,
  width: 20,
  minHeight: 20,
  height: 20,
}

export const styleVertMiddle = { verticalAlign: "middle" }
export const styleNoWrap = { whiteSpace: "nowrap" }
export const styleBackgroundEE = { background: "#eeeeee" }
export const styleWidth300 = { width: 300 }
export const styleWidth100Pc = { width: "100%" }
export const stylePadding0 = { padding: 0 }
export const styleMt16 = { marginTop: "16px" }

export const sizeXs2Sm8Md12 = {
  xs: 2,
  sm: 8,
  md: 12,
}

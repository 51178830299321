import { CircularProgressProps, Tooltip } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import IconError from "@/components/icons/IconError"
import IconMalwareSafe from "@/components/icons/IconMalwareSafe"
import IconMalwareScan from "@/components/icons/IconMalwareScan"
import IconMalwareThreat from "@/components/icons/IconMalwareThreat"
import {
  MuiCircularProgress,
  MuiCircularProgressBox,
  MuiCircularProgressTypography,
} from "@/components/progress/Progress.styled"
import { MalwareScanResult, MalwareScanStatus } from "@/graphql"

type Props = {
  status?: MalwareScanStatus
  result?: MalwareScanResult
  completed: number
}

interface IconContainerProps extends CircularProgressProps {
  title: string
}

export const IconContainer: React.FC<
  React.PropsWithChildren<IconContainerProps>
> = ({ size = 32, thickness = 4, ...props }) => {
  return (
    <Tooltip title={props.title}>
      <MuiCircularProgressBox $transparentBackground={true}>
        <MuiCircularProgress
          size={size}
          thickness={thickness}
          {...props}
          variant={props.value ? "determinate" : "indeterminate"}
        />

        <MuiCircularProgressTypography>
          {props.children}
        </MuiCircularProgressTypography>
      </MuiCircularProgressBox>
    </Tooltip>
  )
}

const UploadTaskMalwareIcon: React.FC<Props> = ({
  status,
  result,
  completed,
}) => {
  const { t } = useTranslation()

  if (completed !== 1) {
    return <></>
  }

  if (completed === 1 && !status && !result) {
    return (
      <IconContainer color="info" title={t("MalwareScanInProgress")}>
        <IconMalwareScan color="info" />
      </IconContainer>
    )
  }

  if (status !== MalwareScanStatus.Complete) {
    return <IconError color="error" />
  }

  switch (result) {
    case MalwareScanResult.AccessDenied:
    case MalwareScanResult.Failed:
    case MalwareScanResult.Unsupported:
      return <IconError color="error" />

    case MalwareScanResult.ThreatsFound:
      return (
        <IconContainer
          color="error"
          value={100}
          title={t("MalwareThreatFound")}
        >
          <IconMalwareThreat color="error" />
        </IconContainer>
      )

    case MalwareScanResult.NoThreatsFound:
      return (
        <IconContainer
          color="success"
          value={100}
          title={t("MalwareNoThreatFound")}
        >
          <IconMalwareSafe color="success" />
        </IconContainer>
      )

    default:
      return <IconError color="error" />
  }
}

export default UploadTaskMalwareIcon

export const blacklistedFileExtensions: string[] = [
  "exe",
  "com",
  "bat",
  "cmd",
  "vb",
  "vbs",
  "vbs",
  "ws",
  "wsf",
  "scf",
  "scr",
  "pif",
  "inf",
  "js",
  "jse",
  "ps1",
  "ps1xml",
  "ps2",
  "ps2xml",
  "psc1",
  "psc2",
  "msh",
  "msh1",
  "msh2",
  "mshxml",
  "msh1xml",
  "msh2xml",
  "sh",
  "shar",
  "dll",
  "rb",
  "erb",
  "ini",
  "msi",
  "config",
  "lnk",
  "bat",
  "swf",
  "mso",
  "filepart",
  "webloc",
  "jnlp",
  "xt",
  "lnk2",
  "crdownload",
  "partial",
  "DS_Store",
  "part",
]

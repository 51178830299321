import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { BreadcrumbsProps, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { validate as validateUuid } from "uuid"

import MuiBreadcrumbs from "@/components/Breadcrumb/Breadcrumb.styled"
import Link from "@/components/Link"
import useBreadcrumb from "@/hooks/useBreadcrumb"

interface CrumbLink {
  name: string
  href: string
}

type CrumbLinkArray = CrumbLink[]

export const Breadcrumb: React.FC<BreadcrumbsProps> = ({
  separator = <NavigateNextIcon fontSize="small" />,
  ...props
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [breadcrumbLinks, setBreadcrumbLinks] = useState<CrumbLinkArray>([])
  const [breadcrumbTypo, setBreadcrumbTypo] = useState<string>("")
  const { breadcrumbLabel } = useBreadcrumb()

  const { pathname } = location

  useEffect(() => {
    const unfilteredSegments = pathname.split("/").filter(Boolean)
    const segments = unfilteredSegments.filter((segment) => {
      return isNaN(Number(segment)) && !validateUuid(segment)
    })

    const links: CrumbLinkArray = []

    if (
      segments.length > 0 &&
      !(segments.length === 1 && segments[0].toLocaleLowerCase() === "home")
    ) {
      let isEdit = false
      let isShow = false
      let isAction = false

      if (
        segments.length === 3 &&
        segments[1].toLocaleLowerCase() === "action"
      ) {
        isAction = true
      } else {
        if (
          ((segments[0] === "settings" || segments[0] === "serviceNote") &&
            segments.length > 1) ||
          (segments[0] === "planning" && segments.length === 1)
        ) {
          isShow = true
        } else {
          isEdit = segments[segments.length - 1].toLocaleLowerCase() === "edit"
          if (unfilteredSegments.length > segments.length) {
            isShow = !isEdit
          }
        }
      }

      let zeroTaken = false
      for (let i = 0; i < segments.length; i++) {
        if (i === segments.length - 1 && !isShow) {
          const lastSegment = segments[segments.length - 1].toLocaleLowerCase()
          if (isEdit) {
            setBreadcrumbTypo(breadcrumbLabel)
          } else {
            if (lastSegment === "new" || isAction) {
              setBreadcrumbTypo(
                t(
                  `Breadcrumb.${segments[
                    segments.length - 2
                  ].toLocaleLowerCase()}.${lastSegment}` as any,
                ),
              )
            } else {
              setBreadcrumbTypo(t(`Breadcrumb.${lastSegment}.name` as any))
            }
          }
        } else {
          if (!zeroTaken || isAction) {
            if (zeroTaken) {
              const link = {
                name: breadcrumbLabel,
                href: `/${segments[0]}/${
                  unfilteredSegments[unfilteredSegments.length - 1]
                }`,
              }

              links.push(link)
            } else {
              zeroTaken = true

              const url =
                segments[0] === "serviceNote"
                  ? `/${segments[0]}/Show`
                  : `/${segments[0]}`

              const link = {
                name: t(
                  `Breadcrumb.${segments[0].toLocaleLowerCase()}.name` as any,
                ),
                href: url,
              }

              links.push(link)
            }
          }
        }
      }

      if (isShow) {
        setBreadcrumbTypo(breadcrumbLabel)
      }
    } else {
      setBreadcrumbTypo("")
    }

    setBreadcrumbLinks(links)
  }, [pathname, t, breadcrumbLabel])

  let key = 1

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" separator={separator} {...props}>
      <Link underline="hover" key={key++} color="inherit" href="/">
        Broca
      </Link>
      {breadcrumbLinks?.map(({ name, href }) => (
        <Link underline="hover" key={key++} color="inherit" href={href}>
          {name}
        </Link>
      ))}
      {breadcrumbTypo && (
        <Typography key={key++} color="text.primary">
          {breadcrumbTypo}
        </Typography>
      )}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumb

import MuiAlert, { AlertProps } from "@mui/material/Alert"

export interface Props extends AlertProps {
  /* Broca Props  */
}

const defaultSx = {
  mb: 2,
  mt: 2,
}

export const Alert: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <MuiAlert sx={sx ?? defaultSx} {...props}>
      {children}
    </MuiAlert>
  )
}

export default Alert

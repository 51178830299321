import {
  MalwareScanResult,
  MalwareScanStatus,
  useOnUpdatedMalwareScanProgressSubscription,
} from "@/graphql"

type Props = {
  uuid: string
  guidFileName: string
  onMalwareScan?: (
    fileId: string,
    status: MalwareScanStatus,
    result: MalwareScanResult,
  ) => void
}

const MalwareSubscriber: React.FC<Props> = ({
  uuid,
  guidFileName,
  onMalwareScan,
}) => {
  useOnUpdatedMalwareScanProgressSubscription({
    variables: {
      onUpdatedMalwareScanProgressKey: guidFileName,
    },
    onData: ({ data }) => {
      const scanProgress = data?.data?.onUpdatedMalwareScanProgress
      if (scanProgress?.key === guidFileName && onMalwareScan) {
        onMalwareScan(uuid, scanProgress.status, scanProgress.result)
      }
    },
  })

  return <></>
}

export default MalwareSubscriber

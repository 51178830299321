import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserSessionData,
} from "amazon-cognito-identity-js"
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"

import {
  ActionMap,
  AuthAccess,
  AuthState,
  AuthUser,
  CognitoContextType,
} from "@/types/auth"

const INITIALIZE = "INITIALIZE"
const SIGN_OUT = "SIGN_OUT"

let UserPool: CognitoUserPool

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  access: null,
}

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean
    user: AuthUser
    access: AuthAccess
  }
  [SIGN_OUT]: undefined
}

type CognitoActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]

const reducer = (state: AuthState, action: CognitoActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user, access } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      access,
    }
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      access: null,
    }
  }
  return state
}

const signOutProcs: Record<string, () => Promise<void>> = {}

interface CognitoConfig {
  userPoolId: string
  userPoolClientId: string
  graphqlApiUri: string
  awsRegion: string
  samlIdentityProvider?: string
  logo: string
}

export type LoadConfigResult = CognitoConfig | null

const loadConfig = (): LoadConfigResult => {
  const brocaMeta = document.querySelector('meta[name="broca"]')
  if (!brocaMeta) {
    throw new Error("No broca meta tag.")
  }

  const metaContent = brocaMeta.getAttribute("content")
  if (!metaContent) {
    throw new Error("No broca meta content.")
  }

  return JSON.parse(atob(metaContent))
}

const applyConfig = (config: CognitoConfig) => {
  UserPool = new CognitoUserPool({
    UserPoolId: config.userPoolId,
    ClientId: config.userPoolClientId,
  })
}

const AuthContext = createContext<CognitoContextType | null>(null)

const getSessionAsync = (
  user: CognitoUser,
): Promise<CognitoUserSession | undefined> => {
  return new Promise<CognitoUserSession | undefined>((resolve, reject) => {
    user.getSession(
      (err: Error | null, session: CognitoUserSession | undefined) => {
        if (err) {
          reject(err)
        } else {
          resolve(session)
        }
      },
    )
  })
}

const forgotPassword = (email: string) => {
  return new Promise<void>((resolve, reject) => {
    const userData = {
      Username: email,
      Pool: UserPool,
    }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve(undefined)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

const changePassword = (oldPassword: string, newPassword: string) => {
  return new Promise<void>((resolve, reject) => {
    const user = UserPool.getCurrentUser()
    if (!user) {
      reject(new Error("No user logged in."))
      return
    }
    getSessionAsync(user)
    user.changePassword(oldPassword, newPassword, (err) => {
      if (err) {
        reject(err)
        return
      }
      resolve(undefined)
    })
  })
}

const newPassword = (newPassword: string, state: string) => {
  const userObject = JSON.parse(state)
  const user = new CognitoUser({
    Username: userObject.username,
    Pool: UserPool,
    Storage: window.localStorage,
  })

  Object.assign(user, { Session: userObject.Session })

  return new Promise<void>((resolve, reject) => {
    user.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: () => {
          resolve(undefined)
        },
        onFailure: (err) => {
          reject(err)
        },
      },
    )
  })
}

const resetPassword = (
  email: string,
  verifyCode: string,
  newPassword: string,
) => {
  return new Promise<void>((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    })

    user.confirmPassword(verifyCode, newPassword, {
      onSuccess: () => {
        resolve(undefined)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

const refreshSessionAsync = (
  user: CognitoUser,
  refreshToken: CognitoRefreshToken,
): Promise<CognitoUserSession | undefined> => {
  return new Promise<CognitoUserSession | undefined>((resolve, reject) => {
    user.refreshSession(
      refreshToken,
      (err: Error | null, session: CognitoUserSession | undefined) => {
        if (err) {
          reject(err)
        } else {
          resolve(session)
        }
      },
    )
  })
}

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const getSession = useCallback(async (forceRefresh?: boolean) => {
    const user = UserPool.getCurrentUser()
    if (!user) {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          access: null,
        },
      })
      return undefined
    }

    let session = await getSessionAsync(user)
    if (session && (!session.isValid() || forceRefresh)) {
      session = await refreshSessionAsync(user, session.getRefreshToken())
    }

    if (!session?.isValid()) {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          access: null,
        },
      })
      return undefined
    }

    const idToken = session.getIdToken()
    const accessToken = session.getAccessToken()
    const pl = idToken.decodePayload()
    const at = accessToken.decodePayload()

    dispatch({
      type: INITIALIZE,
      payload: {
        isAuthenticated: true,
        user: pl,
        access: at,
      },
    })

    return session
  }, [])

  const initialize = useCallback(async () => {
    try {
      const config = loadConfig()
      if (!config) {
        throw new Error("Unable to load runtime config.")
      }
      applyConfig(config)
      await getSession()
    } catch {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          access: null,
        },
      })
    }
  }, [getSession])

  useEffect(() => {
    initialize()
  }, [initialize])

  const setSession = useCallback(
    async (samlResponse: ICognitoUserSessionData) => {
      const userSession = new CognitoUserSession(samlResponse)
      const username = userSession.getIdToken().payload["cognito:username"]
      const samlUser = new CognitoUser({
        Username: username,
        Pool: UserPool,
      })

      samlUser.setSignInUserSession(userSession)
      await getSession()
    },
    [getSession],
  )

  const signIn = useCallback(
    (email: string, password: string) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        })

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        })

        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            getSession()
            resolve(data)
          },
          onFailure: (err) => {
            reject(err)
          },
          newPasswordRequired: () => {
            resolve({ message: "New password required", user: user })
          },
        })
      }),
    [getSession],
  )

  const signOut = useCallback(async () => {
    const user = UserPool.getCurrentUser()
    if (user) {
      for (const proc in signOutProcs) {
        await signOutProcs[proc]()
      }

      user.signOut()
      dispatch({ type: SIGN_OUT })
    }
  }, [dispatch])

  const getToken = useCallback(
    async (
      getAccessToken?: boolean,
      forceRefresh?: boolean,
    ): Promise<string> => {
      const session = await getSession(forceRefresh)
      if (!session) return ""

      if (getAccessToken) {
        return session.getAccessToken().getJwtToken()
      }
      return session.getIdToken().getJwtToken()
    },
    [getSession],
  )

  const getCurrentRefreshToken = useCallback(async () => {
    const session = await getSession(false)
    return session?.getRefreshToken().getToken()
  }, [getSession])

  const [avatarKey, setAvatarKey] = useState(0)

  const forceRefreshAvatar = useCallback(() => {
    setAvatarKey((prevKey) => prevKey + 1)
  }, [])

  const authContextProviderValue: CognitoContextType = useMemo(() => {
    return {
      ...state,
      method: "cognito",
      user: {
        displayName: state?.user?.given_name || "Undefined",
        role: "user",
        ...state.user,
      },
      setSession,
      signIn,
      signOut,
      forgotPassword,
      changePassword,
      resetPassword,
      newPassword,
      getToken,
      forceRefreshAvatar,
      getCurrentRefreshToken,
      avatarKey,
    }
  }, [
    state,
    setSession,
    signIn,
    signOut,
    getToken,
    avatarKey,
    forceRefreshAvatar,
    getCurrentRefreshToken,
  ])

  return (
    <AuthContext.Provider value={authContextProviderValue}>
      <>{children}</>
    </AuthContext.Provider>
  )
}

const getCurrentUser = (): CognitoUser | null => {
  if (!UserPool) return null
  return UserPool.getCurrentUser()
}

const registerSignOutProc = (name: string, proc: () => Promise<void>) => {
  signOutProcs[name] = proc
}

export {
  AuthContext,
  AuthProvider,
  getCurrentUser,
  loadConfig,
  registerSignOutProc,
}

import { BrocaTheme } from "@mui/material"
import { deepPurple, green, grey, pink, red } from "@mui/material/colors"
import merge from "deepmerge"

import { THEMES } from "@/constants"

export const broca_green = {
  100: "#004d3b", // Broca Brand Primary Color Green (Darker 80%)
  200: "#00614d", // Broca Brand Primary Color Green (Darker 60%)
  300: "#00735b", // Broca Brand Primary Color Green (Darker 40%)
  400: "#008869", // Broca Brand Primary Color Green (Darker 20%)
  500: "#00aa84", // Broca Brand Primary Color Green
  600: "#00cc9e", // Broca Brand Primary Color Green (Lighter 20%)
  700: "#00eeb8", // Broca Brand Primary Color Green (Lighter 40%)
  800: "#99ddbfff", // Broca Brand Primary Color Green (Lighter 60%)
}

export const broca_grey = {
  300: "#625757", // Broca Brand Primary Color Grey (Darker 40%)
  500: "#9a9695", // Broca Brand Primary Color Grey
  600: "#bcb9b8", // Broca Brand Primary Color Grey (Lighter 20%)
  700: "#E4E1E0", // Broca Brand Primary Color Grey (Lighter 40%)
  800: "#F8F5F4", // Broca Brand Primary Color Grey (Lighter 60%)
  900: "#FBF9F8", // Broca Brand Primary Color Grey (Lighter 70%)
}

export const broca_blue = {
  100: "#2a6b7d", // Broca Brand Primary Color Blue (Darker 80%)
  200: "#3f7f91", // Broca Brand Primary Color Blue (Darker 60%)
  300: "#4f8f9f", // Broca Brand Primary Color Blue (Darker 50%)
  400: "#5b9db1", // Broca Brand Primary Color Blue (Darker 40%)
  500: "#8ac4d6", // Broca Brand Primary Color Blue (Darker 20%)
  600: "#c7e7f1", // Broca Brand Primary Color Blue
}

export const broca_red = "#ff7d86"

const defaultVariant: BrocaTheme = {
  name: THEMES.DEFAULT,
  style: {
    borderColor: "#e0e0e0",
    padding: {
      tiny: "2px",
      small: "4px",
      medium: "8px",
      large: "16px",
      xlarge: "24px",
      huge: "32px",
    },
    fontSize: {
      tiny: "10px",
      small: "12px",
      medium: "14px",
      large: "16px",
      xlarge: "20px",
      huge: "24px",
    },
    card: {
      border: "1px solid #e0e0e0",
      borderColor: "#e0e0e0",
    },
    cardHeader: {
      backgroundColor: "#000",
      color: "#fff",
    },
    cardContent: {
      backgroundColor: "#fff",
      color: "#000",
    },
    cardActions: {
      backgroundColor: "#e6e6e6",
      color: "#000",
    },
    table: {
      borderColor: "#e0e0e0",
      thead: {
        backgroundColor: "#f0f0f0",
      },
    },
    link: {
      color: "#1EA7FD",
      hover: "#8F0D00",
    },
  },
  palette: {
    global: {
      inputLabel: "#555",
    },
    progress: {
      background: grey[300],
      color: red[700],
      primary: red[700],
      secondary: grey[800],
      success: green[700],
    },
    primary: {
      main: broca_green[500],
      over: broca_green[400],
      contrastText: "#FFF",
    },
    secondary: {
      main: broca_grey[500],
      over: broca_grey[300],
      contrastText: "#FFF",
    },
    tertiary: {
      main: broca_blue,
      over: broca_blue,
      contrastText: "#FFF",
    },
    quaternary: {
      main: broca_red,
      over: broca_red,
      contrastText: "#FFF",
    },
    dataGrid: {
      header: {
        color: "#4f4f4f",
        background: "#F0F0F0",
      },
      status: {
        processing: "#f7be5d",
        waiting: "#c35141",
      },
    },
    fieldset: {
      borderColor: grey[300],
      textColor: grey[900],
      labelColor: grey[700],
    },
    error: red,
    background: {
      default: grey[200],
      paper: "#FFF",
    },
    logo: {
      color: "#ed1c24",
      textColor: "#231f20",
    },
    status: {
      default: {
        background: grey[200],
        color: grey[800],
      },
      selected: {
        background: grey[300],
        color: grey[800],
      },
      focus: {
        background: grey[400],
        color: grey[800],
      },
    },
  },
  header: {
    color: grey[500],
    background: "#fff",
    search: {
      color: grey[800],
    },
    indicator: {
      background: red[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#fff",
  },
  sidebar: {
    active: "#404040",
    color: "#fff",
    background: broca_grey[300],
    iconSize: "32px",
    header: {
      color: grey[200],
      background: grey[100],
      border: grey[400],
      brand: {
        color: grey[200],
      },
    },
    footer: {
      color: grey[200],
      background: grey[100],
      online: {
        background: green[500],
      },
    },
  },
  status: {
    default: grey[200],
    selected: grey[300],
    focus: grey[400],
  },
}

const darkVariant: BrocaTheme = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "Default",
    primary: {
      main: grey[700],
      over: grey[900],
      contrastText: "#FFF",
    },
    secondary: {
      main: grey[500],
      over: grey[700],
      contrastText: "#FFF",
    },
    tertiary: {
      main: broca_blue,
      over: broca_blue,
      contrastText: "#FFF",
    },
    quaternary: {
      main: broca_red,
      over: broca_red,
      contrastText: "#FFF",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
    background: {
      default: grey[700],
      paper: "#f6f6f6",
    },
    logo: {
      color: "#444",
      textColor: "#666",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
})

const lpVariant: BrocaTheme = merge(defaultVariant, {
  name: THEMES.LP,
  palette: {
    primary: {
      main: deepPurple[700],
      over: deepPurple[900],
      contrastText: pink[100],
    },
    secondary: {
      main: deepPurple[500],
      over: deepPurple[700],
      contrastText: pink[100],
    },
    tertiary: {
      main: broca_blue,
      over: broca_blue,
      contrastText: "#FFF",
    },
    quaternary: {
      main: broca_red,
      over: broca_red,
      contrastText: "#FFF",
    },
    error: red,
    background: {
      default: deepPurple[200],
      paper: pink[200],
    },
    logo: {
      color: deepPurple[700],
      textColor: pink[900],
    },
  },
  header: {
    color: deepPurple[200],
    background: deepPurple[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: grey[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: "#FFF",
    header: {
      color: "#FFF",
      background: deepPurple[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[800],
      background: "#F7F7F7",
      online: {
        background: green[500],
      },
    },
  },
})

export const variants: Array<BrocaTheme> = [
  defaultVariant,
  darkVariant,
  lpVariant,
]

export { darkVariant, defaultVariant, lpVariant }

export default variants

import Avatar from "@mui/material/Avatar"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { Stack } from "@mui/system"
import { nanoid } from "@reduxjs/toolkit"
import axios from "axios"
import { useCallback, useEffect, useRef, useState } from "react"
import { Accept } from "react-dropzone"
import { Trans } from "react-i18next"
import ReactCrop, { Crop, PixelCrop } from "react-image-crop"
import Resizer from "react-image-file-resizer"
import { useSelector } from "react-redux"

import Button from "@/components/Button"
import DragDrop from "@/components/DragDrop"
import IconButton from "@/components/IconButton"
import IconEdit from "@/components/icons/IconEdit"
import MalwareSubscriber from "@/components/MalwareSubscriber"
import UploadTaskMalwareIcon from "@/components/UploadTaskMalwareIcon"
import { styleDisplayNone } from "@/constants"
import { MalwareScanResult, MalwareScanStatus } from "@/graphql"
import useAppDispatch from "@/hooks/useAppDispatch"
import useAuth from "@/hooks/useAuth"
import {
  addUploadTaskAsync,
  selectUploadedFiles,
  setMalwareScan,
  useUploadTaskSelector,
} from "@/redux/slices/uploadManager"
import "react-image-crop/dist/ReactCrop.css"
import { blacklistedFileExtensions } from "@/shared/utils/extensionsBlacklist"

const canvasPreview = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: Crop,
  scale: number = 1,
  rotate: number = 0,
) => {
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    throw new Error("No 2d context")
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = "high"

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const to_radians = Math.PI / 180
  const rotateRads = rotate * to_radians
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  )

  ctx.restore()
}

const accept: Accept = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/gif": [".gif"],
  "image/bmp": [".bmp"],
  "image/webp": [".webp"],
}

export type AvatarFieldProps = {
  id?: string
  labelId?: string
  label?: string
  value?: string
  defaultValue?: string
  fullWidth?: boolean
  disabled?: boolean
  name?: string
  error?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

const AvatarField: React.FC<AvatarFieldProps> = ({
  onChange,
  value,
  disabled,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [original, setOriginal] = useState("")
  const [preview, setPreview] = useState("")
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<Crop>()
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const { getToken } = useAuth()
  const [initialValue, setInitialValue] = useState<string>()
  const [initialized, setInitialized] = useState(false)
  const [uuid, setUuid] = useState("")

  useEffect(() => {
    if (!value || initialized) return
    setInitialValue(value)
    setInitialized(true)
  }, [value, setInitialValue, initialized, setInitialized])

  useEffect(() => {
    if (!initialValue || !getToken || !initialized) return

    const fetchImage = async () => {
      const accessToken = await getToken(true)
      if (!accessToken) return

      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "https://mougou.broca-dev.ca"
          : ""
      const endpoint = `${baseUrl}/avatar/${initialValue}`
      const response = await axios.get(endpoint, {
        headers: { Authorization: accessToken },
        responseType: "arraybuffer",
      })
      const data = `data:${
        response.headers["content-type"]
      };base64,${Buffer.from(response.data, "binary").toString("base64")}`
      setPreview(data)
    }

    fetchImage()
  }, [initialValue, setPreview, getToken, initialized])

  useEffect(() => {
    if (!hiddenRef.current || value === undefined) return
    hiddenRef.current.value = value
  }, [value, hiddenRef])

  const uploadedAvatarFiles = useSelector((state) =>
    selectUploadedFiles(state, "avatar"),
  )

  const task = useUploadTaskSelector(uuid)
  const guidFileName = task?.task?.guidFileName

  useEffect(() => {
    if (
      !hiddenRef.current ||
      !uploadedAvatarFiles?.length ||
      task?.malwareResult !== "NoThreatsFound"
    )
      return undefined
    const fileName = `${uploadedAvatarFiles[0].guidFileName}.webp`
    const proto = window?.HTMLInputElement?.prototype
    if (!proto) return
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      proto,
      "value",
    )?.set
    if (!nativeInputValueSetter) return
    nativeInputValueSetter.call(hiddenRef.current, fileName)
    const ev2 = new Event("input", { bubbles: true })
    hiddenRef.current.dispatchEvent(ev2)
  }, [uploadedAvatarFiles, hiddenRef, onChange, task])

  const handleClickOpen = useCallback(() => {
    setOriginal("")
    setCrop(undefined)
    setCompletedCrop(undefined)
    setOpen(true)
  }, [setOriginal, setCrop, setCompletedCrop, setOpen])

  const handleCancel = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleApply = useCallback(() => {
    if (!imgRef.current || !completedCrop?.width || !completedCrop?.height)
      return

    const canvas = document.createElement("canvas")
    canvasPreview(imgRef.current, canvas, completedCrop, 1, 0)
    canvas.toBlob((blob) => {
      if (blob) {
        Resizer.imageFileResizer(
          blob,
          100,
          100,
          "WEBP",
          100,
          0,
          (resizedBlob) => {
            const file = new File([resizedBlob as Blob], "avatar.webp", {
              type: "image/webp",
            })

            const newUuid = nanoid()
            setUuid(newUuid)

            dispatch<any>(
              addUploadTaskAsync({
                uuid: newUuid,
                filePath: "avatar",
                files: [file],
              }),
            )

            setPreview(URL.createObjectURL(file))
          },
          "blob",
        )
      } else {
        setPreview("")
      }
    })
    canvas.remove()
    setOpen(false)
  }, [dispatch, imgRef, completedCrop, setOpen, setPreview, setUuid])

  const changeHandler = useCallback(
    (crop: PixelCrop) => {
      setCrop(crop)
    },
    [setCrop],
  )

  const completeHandler = useCallback(
    (crop: PixelCrop) => {
      setCompletedCrop(crop)
    },
    [setCompletedCrop],
  )

  const onDropAccepted = useCallback(
    (files: File[]) => {
      if (files.length) {
        const file = files[0]
        const extension = file.name.split(".").pop()?.toLowerCase() ?? ""
        if (!blacklistedFileExtensions.includes(extension)) {
          setOriginal(URL.createObjectURL(files[0]))
        }
      }
    },
    [setOriginal],
  )

  const malwareScanHandler = useCallback(
    (uuid: string, status: MalwareScanStatus, result: MalwareScanResult) => {
      dispatch<any>(
        setMalwareScan({
          uuid,
          status,
          result,
        }),
      )
    },
    [dispatch],
  )

  const applyDisabled =
    !completedCrop?.width || !completedCrop?.height || !original

  return (
    <FormControl fullWidth={props.fullWidth} error={props.error}>
      <FormLabel id={props.labelId}>{props.label}</FormLabel>
      <>
        {guidFileName && (
          <MalwareSubscriber
            uuid={uuid}
            guidFileName={guidFileName}
            onMalwareScan={malwareScanHandler}
          />
        )}
        <input
          ref={hiddenRef}
          type="text"
          name={props.name}
          id={props.id}
          onBlur={props.onBlur}
          onChange={onChange}
          style={styleDisplayNone}
        />
        <Stack spacing={2} direction="row" alignItems="center">
          <Avatar alt="avatarPreview" src={preview} />
          {task && (
            <UploadTaskMalwareIcon
              completed={task.completed}
              status={task.malwareStatus}
              result={task.malwareResult}
            />
          )}
          {!disabled && (
            <IconButton size="small" onClick={handleClickOpen}>
              <IconEdit />
            </IconButton>
          )}
        </Stack>
        <Dialog
          open={open}
          onClose={handleCancel}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>
            <Trans>AvatarImage</Trans>
          </DialogTitle>
          <DialogContent>
            {original ? (
              <ReactCrop
                circularCrop={true}
                aspect={1}
                crop={crop}
                onChange={changeHandler}
                onComplete={completeHandler}
              >
                <img
                  crossOrigin="anonymous"
                  ref={imgRef}
                  src={original}
                  alt="originalImage"
                />
              </ReactCrop>
            ) : (
              <DragDrop onDropAccepted={onDropAccepted} accept={accept} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>
              <Trans>Cancel</Trans>
            </Button>
            <Button disabled={applyDisabled} onClick={handleApply}>
              <Trans>Apply</Trans>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </FormControl>
  )
}

export default AvatarField
